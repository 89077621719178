import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,[_c(VCardTitle,{staticClass:"align-start"},[_c(VAvatar,{staticClass:"elevation-3",attrs:{"color":_vm.color,"size":"38"}},[_c(VIcon,{staticClass:"rounded-0",attrs:{"size":"24","color":"white"}},[_vm._v(" "+_vm._s(_vm.icon)+" ")])],1),_c(VSpacer),_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({staticClass:"me-n3 mt-n1",attrs:{"small":"","icon":""}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v(" "+_vm._s(_vm.mdiInformationVariant)+" ")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.description))])])],1),_c(VCardText,[_c('div',{staticClass:"text-no-wrap text--primary mt-3"},[_c('p',{staticClass:"font-weight-semibold text-sm mb-1"},[_vm._v(" "+_vm._s(_vm.statTitle)+" ")]),_c('div',{staticClass:"d-flex align-end flex-wrap"},[_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"font-weight-semibold text-2xl me-1 mb-2"},'span',attrs,false),on),[_vm._v(_vm._s(_vm.statistics))])]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('SelectedPeriod')))])])],1),_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('p',_vm._g(_vm._b({staticClass:"text-xs text--secondary mb-0"},'p',attrs,false),on),[_vm._v(" "+_vm._s(_vm.subtitle)+" ")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('AllPeriod')))])])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-card>
    <v-card-title class="align-start">
      <v-avatar
        :color="color"
        size="38"
        class="elevation-3"
      >
        <v-icon
          size="24"
          color="white"
          class="rounded-0"
        >
          {{ icon }}
        </v-icon>
      </v-avatar>
      <v-spacer></v-spacer>
      <v-tooltip bottom>
        <template #activator="{ on, attrs }">
          <v-btn
            small
            icon
            class="me-n3 mt-n1"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>
              {{ mdiInformationVariant }}
            </v-icon>
          </v-btn>
        </template>
        <span>{{ description }}</span>
      </v-tooltip>
    </v-card-title>
    <v-card-text>
      <div class="text-no-wrap text--primary mt-3">
        <p class="font-weight-semibold text-sm mb-1">
          {{ statTitle }}
        </p>

        <div class="d-flex align-end flex-wrap">
          <v-tooltip top>
            <template #activator="{ on, attrs }">
              <span
                v-bind="attrs"
                class="font-weight-semibold text-2xl me-1 mb-2"
                v-on="on"
              >{{ statistics }}</span>
            </template>
            <span>{{ $t('SelectedPeriod') }}</span>
          </v-tooltip>
        </div>
        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <p
              class="text-xs text--secondary mb-0"
              v-bind="attrs"
              v-on="on"
            >
              {{ subtitle }}
            </p>
          </template>
          <span>{{ $t('AllPeriod') }}</span>
        </v-tooltip>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { mdiInformationVariant } from '@mdi/js'

export default {
  props: {
    statTitle: { type: String, default: '' },
    icon: { type: String, default: '' },
    color: { type: String, default: '' },
    subtitle: { type: [String, Number], default: 0 },
    statistics: { type: [String, Number], default: 0 },
    description: { type: String, default: '' },
  },
  setup() {
    return {
      mdiInformationVariant,
    }
  },
}
</script>

<style lang="scss" scoped>
.percentage {
  top: -8px;
  position: relative;
}
</style>
